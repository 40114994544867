angular.module('CaresApp').controller('ForgotCtrl',
  [
    '$scope',
    '$document',
    '$http',
    '$timeout',
    'OcrUtils',
    function($scope, $document, $http, $timeout, OcrUtils) {
      OcrUtils.setPageTitle('Forgot Password')
      $scope.forms = {}; // so we can use the forms from the controller
      $scope.forgot = {};
      $scope.done = false;

      $scope.$parent.disableMenu = false;

      $scope.resetFocus = function() {
        $timeout(function() {
          $document[0].getElementById('emailAddress').focus();
        }, 250);
      };

      $scope.resetFocus();

      $scope.submit = function() {
        $scope.message = "";

        $scope.promise = $http.post("/api/forgot", {
          email: $scope.forgot.emailAddress.toLowerCase(),
        })
          .then(function() {
            $scope.message
            = "An email with password reset instructions has been sent to your email address.";
            $scope.promise = undefined;
            $scope.done = true;
          })
          .catch(function() {
            $scope.message
            = "The email address that you submitted is not in our records. Please retry.";
            $scope.promise = undefined;
            $scope.resetFocus();
          });
      };
    },
  ]);
