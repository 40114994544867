angular.module('CaresApp').controller('ViewCtrl', [
  '$scope',
  '$document',
  '$mdSidenav',
  '$location',
  '$window',
  '$mdMedia',
  '$http',
  '$routeParams',
  '$mdDialog',
  '$timeout',
  'OcrAuth',
  'OcrUtils',
  'OcrViews',
  'OcrLookups',
  function($scope, $document, $mdSidenav, $location, $window, $mdMedia, $http,
    $routeParams, $mdDialog, $timeout, OcrAuth,
    OcrUtils, OcrViews, OcrLookups,
  ) {
    $scope.promise = undefined;

    $scope.query = {
      order: undefined,
      limit: 20,
      page: 1,
    };

    $scope.userSettings = {};
    $scope.search = {};

    $scope.searchViews = [];
    $scope.searchViews.push("additional_funds");
    $scope.searchViews.push("general_settings");
    $scope.searchViews.push("my_contacts");
    $scope.searchViews.push("my_placements");
    $scope.searchViews.push("my_office");
    $scope.searchViews.push("party_search");
    $scope.searchViews.push("activity_groups");
    $scope.searchViews.push("appointment_office_access_request_reasons");
    $scope.searchViews.push("attorney_roles");
    $scope.searchViews.push("activity_status");
    $scope.searchViews.push("activity_types");
    $scope.searchViews.push("appointment_capacities");
    $scope.searchViews.push("best_interests_continuation_reasons");
    $scope.searchViews.push("billable_item_types");
    $scope.searchViews.push("case_types");
    $scope.searchViews.push("child_party_court_attendance_options");
    $scope.searchViews.push("child_party_end_reasons");
    $scope.searchViews.push("counties");
    $scope.searchViews.push("educational_settings");
    $scope.searchViews.push("exception_log");
    $scope.searchViews.push("genders");
    $scope.searchViews.push("hearing_types");
    $scope.searchViews.push("involved_person_types");
    $scope.searchViews.push("judicial_districts");
    $scope.searchViews.push("offices");
    $scope.searchViews.push("office_activities");
    $scope.searchViews.push("other_approvals");
    $scope.searchViews.push("permanency_goals");
    $scope.searchViews.push("permissions");
    $scope.searchViews.push("personas");
    $scope.searchViews.push("placements");
    $scope.searchViews.push("placement_types");
    $scope.searchViews.push("privilege_holder");
    $scope.searchViews.push("race_ethnicities");
    $scope.searchViews.push("request_types");
    $scope.searchViews.push("rules");
    $scope.searchViews.push("states");
    $scope.searchViews.push("upcoming_activities");
    $scope.searchViews.push("users");

    OcrLookups.loadAppSettings();

    $scope.forms = {}; // so we can use the forms from the controller

    $scope.$parent.disableMenu = false;

    $scope.showActiveAppointments = true;

    $scope.passwordStrength = 33;
    $scope.passwordClass = 'red';



    let decoded = decodeURIComponent($location.url());
    let split = decoded.split('#');
    $location.url(split[0]);
    if (split[1]) {
      $location.hash(split[1]);
    }

    $scope.reorder = function() {
      $scope.updateViewData();
    };

    $scope.$on("Searching", function() {});

    $scope.selected = [];

    $scope.openViews = []; // categories open in navbar

    $scope.results = {
      data: [],
      count: 0,
    };

    $scope.result = undefined;

    $scope.age = OcrUtils.age
    //Object.defineProperty($scope, 'views', {get: () => OcrViews.views})

    $scope.getViews = function() {
      $scope.views = [];

      $scope.views = [
        {
          "key": "home",
          "name": "Home",
          "description": "Announcements, case alerts, and appointment and invoice charts",
          // increment cannot be one, because parent views are inserted at child view's index minus
          // one.
          "order": 10,
          "icon": "home",
          "permissions": ["OFFICE_PUBLIC"],
        },
        {
          "key": "my_appointments",
          "name": "Cases/My Appointments",
          "description": "View the cases appointed to or accessed by my office.",
          // increment cannot be one, because parent views are inserted at child view's index minus
          // one.
          "order": 20,
          "icon": "assignment_ind",
          "permissions": ["OFFICE_PUBLIC", "CASE_READ"],
        },
        {
          "key": "cases",
          "name": "Cases/Search Cases",
          "description": "Search for cases and create new ones.",
          // increment cannot be one, because parent views are inserted at child view's index minus
          // one.
          "order": 30,
          "icon": "account_balance",
          "columns": [
            "caseNumber",
            "caseType",
            "caseNickname",
            "judicialDistrict",
            "parties",
            "appointedAttorneys",
          ],
          "headerNames": {
            "caseNumber": "Case Number",
            "caseType": "Case Type",
            "caseNickname": "Case Nickname",
            "judicialDistrict": "Judicial District",
            "parties": "Parties",
            "appointedAttorneys": "Appointed Attorneys",
          },
          "permissions": ["OFFICE_PUBLIC", "CASE_READ"],
        },
        {
          "key": "office_activities",
          "name": "Cases/Office Activities",
          "description": "View the list of all activities for my office.",
          "order": 40,
          "icon": "assignment_ind",
          "permissions": ["OFFICE_PUBLIC", "ACTIVITY_READ"],
        },
        {
          "key": "upcoming_activities",
          "name": "Cases/Upcoming Activities",
          "description": "View and create future activities for appointments.",
          "order": 50,
          "icon": "assignment_ind",
          "permissions": ["OFFICE_PUBLIC", "UPCOMING_READ"],
        },
        {
          "key": "appointment_groups",
          "name": "Cases/Appointment Groups",
          "description" : "Create, edit, or delete the group names of associated appointments in my office.",
          "order": 60,
          "icon": "assignment_ind",
          "permissions": ["OFFICE_PUBLIC"],
        },
        {
          "key": "my_contacts",
          "name": "Address Book/My Contacts",
          "description":
            "Personal list of contacts that may be added to appointments as involved persons.",
          // increment cannot be one, because parent views are inserted at child view's index minus
          // one.
          "order": 70,
          "icon": "contacts",
          "permissions": ["OFFICE_PUBLIC"],
        },
        {
          "key": "my_placements",
          "name": "Address Book/My Placements",
          "description": "Personal list of placements that may be assigned to child parties.",
          // increment cannot be one, because parent views are inserted at child view's index minus
          // one.
          "order": 80,
          "icon": "contacts",
          "permissions": ["OFFICE_PUBLIC"],
        },
        {
          "key": "invoices",
          "name": "Invoicing/Invoices",
          "description": "Create, view, submit, approve, or reject invoices.",
          // increment cannot be one, because parent views are inserted at child view's index minus
          // one.
          "order": 90,
          "icon": "monetization_on",
          "permissions": ["INVOICE_READ"],
        },
        {
          "key": "core-export",
          "name": "Invoicing/CORE Export",
          "description": "Export invoices for CORE importing.",
          "order": 100,
          "icon": "import_export",
          "customSearch": {
            limit: 100,
          },
          "permissions": ["CORE_EXPORT"],
        },
        {
          "key": "additional_funds",
          "name": "Requests/Additional Funds",
          "description": "View additional funding requests",
          "order": 110,
          "icon": "attach_money",
          "permissions": ["BILLING_REQUEST_CREATE", "BILLING_REQUEST_CREATE_OFFICEWIDE", "BILLING_REQUEST_REVIEW", "BILLING_CHANGE"],
          "permissionInclusion": "or",
        },
        {
          "key": "other_approvals",
          "name": "Requests/Other Approvals",
          "description": "View other approval requests",
          "order": 120,
          "icon": "attach_money",
          "permissions": ["EXPENSE_REQUEST_CREATE", "EXPENSE_REQUEST_REVIEW"],
          "permissionInclusion": "or",
        },
        {
          "key": "exception_log",
          "name": "Requests/Exception Log",
          "description": "View exception log",
          "order": 130,
          "icon": "attach_money",
          "permissions": ["EXCEPTION_READ"],
        },
        {
          "key": "reports",
          "name": "Reports",
          "description": "Access reports.",
          "order": 140,
          "icon": "view_column",
          "permissions": ["REPORTS_READ"],
        },
        {
          "key": "activity_groups",
          "name": "Administration/Activity Groups",
          "description": "Manage activity groups.",
          "order": 150,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "activity_status",
          "name": "Administration/Activity Status",
          "description": "Manage activity status options.",
          "order": 160,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "activity_types",
          "name": "Administration/Activity Types",
          "description": "Manage activity types.",
          "order": 170,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "announcements",
          "name": "Administration/Announcements",
          "description": "Manage announcements.",
          "order": 180,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ", "ANNOUNCEMENT_WRITE"],
        },
        {
          "key": "appointment_capacities",
          "name": "Administration/Appointment Capacities",
          "description": "Manage appointment capacities.",
          "order": 190,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "appointment_office_access_request_reasons",
          "name": "Administration/Appointment Office Access Request Reasons",
          "description": "Manage appointment office access request reasons.",
          "order": 195,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "attorney_roles",
          "name": "Administration/Attorney Roles",
          "description": "Attorney Roles are linked to Case Types, so Roles cannot be deleted after creation and Role Type cannot be edited later. Changes to Role (name) will overwrite it if in use by any attorneys. Role Order determines its Child Party display order.",
          "order": 196,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "best_interests_continuation_reasons",
          "name": "Administration/Best Interests Continuation Reasons",
          "description": "Manage best interests continuation reasons.",
          "order": 200,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "billable_item_types",
          "name": "Administration/Billable Item Types",
          "description": "Manage billable item types.",
          "order": 210,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "case_types",
          "name": "Administration/Case Types",
          "description": "Manage case types.",
          "order": 220,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "child_party_court_attendance_options",
          "name": "Administration/Child Party Court Attendance Options",
          "description": "Manage child party court attendance options.",
          "order": 230,
          "usesSearch": true,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "child_party_end_reasons",
          "name": "Administration/Child Party End Reasons",
          "description": "Manage list of child party end reasons.",
          "order": 240,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "counties",
          "name": "Administration/Counties",
          "description": "Manage list of counties.",
          "order": 250,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "educational_settings",
          "name": "Administration/Educational Settings",
          "description": "Manage educational settings.",
          "order": 260,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "url_link_text",
          "name": "Administration/External Links",
          "description": "Manage external links",
          "order": 270,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "genders",
          "name": "Administration/Genders",
          "description": "Manage genders.",
          "order": 280,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "hearing_types",
          "name": "Administration/Hearing Types",
          "description": "Manage hearing types.",
          "order": 290,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "help_text",
          "name": "Administration/Help Text",
          "description": "Manage help text",
          "order": 300,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "involved_person_types",
          "name": "Administration/Involved Person Types",
          "description": "Manage involved person types",
          "order": 310,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "judicial_districts",
          "name": "Administration/Judicial Districts",
          "description": "Manage judicial districts",
          "order": 320,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "offices",
          "name": "Administration/Offices",
          "description": "Manage offices.",
          "order": 330,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "permanency_goals",
          "name": "Administration/Permanency Goals",
          "description": "Manage permanency goals.",
          "order": 340,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "permissions",
          "name": "Administration/Permissions",
          "description": "Manage permissions.",
          "order": 350,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "personas",
          "name": "Administration/Personas",
          "description": "Manage personas.",
          "order": 360,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "placements",
          "name": "Administration/Placements",
          "description": "Manage placements.",
          "order": 370,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "placement_types",
          "name": "Administration/Placement Types",
          "description": "Manage placement types.",
          "order": 380,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "privilege_holder",
          "name": "Administration/Privilege Holders",
          "description": "Manage privilege holders",
          "order": 390,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "race_ethnicities",
          "name": "Administration/Race/Ethnicities",
          "description": "Define race/ethnicity options",
          "order": 400,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "request_types",
          "name": "Administration/Request Types",
          "description": "Define request type options",
          "order": 410,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "rules",
          "name": "Administration/Rules",
          "description": "Define appropriation unit rules",
          "order": 420,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "states",
          "name": "Administration/States",
          "description": "Manage states.",
          "order": 430,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "users",
          "name": "Administration/Users",
          "description": "Manage users.",
          "order": 440,
          "icon": "settings_applications",
          "permissions": ["ADMIN_READ"],
        },
        {
          "key": "general_settings",
          "name": "User Settings/General Settings",
          "description": "General user settings.",
          "order": 450,
          "icon": "settings",
          "permissions": ["OFFICE_PUBLIC"],
        },
        {
          "key": "change_password",
          "name": "User Settings/Change Password",
          "description": "Change user password.",
          "order": 460,
          "icon": "settings",
          "permissions": ["OFFICE_PUBLIC"],
        },
        {
          "key": "my_office",
          "name": "User Settings/My Office",
          "description": "Directory of all users in the current user's office.",
          "order": 470,
          "icon": "settings",
          "permissions": ["OFFICE_PUBLIC"],
        },
        {
          "key": "help",
          "name": "Help",
          "description": "CARES application help pages.",
          "order": 480,
          "icon": "help",
          "permissions": ["OFFICE_PUBLIC", "HELP_READ"],
        },
      ];

      // set view hierarchy
      for (let c = 0; c < $scope.views.length; c++) {
        const view = $scope.views[c];
        view.shortName = view.name;
        if (view.name.indexOf("/") !== -1) {
          const parentName = view.name.substring(0, view.name.indexOf("/"));
          let parentView = $scope.getViewByName(parentName);
          if (parentView === null) {
            parentView = {
              key: `${parentName.replace(/\s/, '').toLowerCase()}Parent`,
              name: parentName,
              hasChildren: true,
              icon: view.icon,
              order: view.order - 1,
            };
            $scope.views.push(parentView);
          }
          view.parent = parentView.key;
          if (!parentView.permissions) {
            parentView.permissions = view.permissions;
          } else {
            Array.prototype.push.apply(parentView.permissions, view.permissions);
          }
          parentView.permissions = parentView.permissions.filter(function(
            str) {
            return str;
          });
          view.shortName = view.name.substring(view.name.indexOf("/")
            + 1);
        }
      }

      $scope.views = $scope.views.sort(function(a, b) {
        if (a.order > b.order) {
          return 1;
        } else if (a.order === b.order) {
          return 0;
        }
        return -1;
      });

      $scope.checkPermissions = function(view) {
        if (OcrAuth.session &&
          angular.isDefined(OcrAuth.session.user)
          && angular.isDefined(view.permissions)
        ) {
          if (angular.isDefined(OcrAuth.session.user.permissions)) {
            if (view.hasChildren
              || (angular.isDefined(view.permissionInclusion)
                && view.permissionInclusion === "or")
            ) {
              for (let i = 0; i < view.permissions.length; i++) {
                if (OcrAuth.session.user.permissions.indexOf(view.permissions[
                  i]) !== -1) {
                  return true;
                }
              }
              return false;
            }
            for (let i = 0; i < view.permissions.length; i++) {
              if (OcrAuth.session.user.permissions.indexOf(view.permissions[
                i]) === -1) {
                return false;
              }
            }
            return true;
          }
        }
        return false;
      };

      $scope.checkPermission = OcrAuth.checkPermission

      if (!$scope.selectedView) {
        //Do nothing. This was causing a LOT of unneeded loading
      } else {

        $scope.selectedViewData = $scope.getViewData($scope.selectedView);

        const view = $scope.getViewData($scope.selectedView);


        if (view && view.parent) {
          $scope.toggleView(view.parent);
        }
      }
    };

    $scope.toggleSidenav = function() {
      if (!$mdSidenav('left').isLockedOpen()) {
        $mdSidenav('left').toggle();
      }
    };

    $scope.toggleView = function(item) {
      const index = $scope.openViews.indexOf(item);
      if (index >= 0) {
        $scope.openViews.splice(index, 1); // remove item from array
      } else {
        $scope.openViews.push(item);
      }
    };

    $scope.isViewOpen = function(item) {
      return ($scope.openViews.indexOf(item) >= 0);
    };

    $scope.clickNavItem = function(item) {
      if (item.hasChildren) {
        $scope.toggleView(item.key);
      } else if (item.key.localeCompare("help") === 0) {
        // clear sort and filter when changing view
        $window.open("https://ocr.zendesk.com/hc/en-us", '_blank');
      } else if (item.customSearch) {
        $location.path(`/views/${item.key}`).search(item.customSearch);
      } else {
        $location.path(`/views/${item.key}`);
      }
    };

    $scope.getViewData = function(viewId) {
      if (!$scope.views) {
        return null;
      }
      for (let c = 0; c < $scope.views.length; c++) {
        if ($scope.views[c].key === viewId) {
          return $scope.views[c];
        }
      }
      return null;
    };

    $scope.getViewByName = function(name) {
      if (!$scope.views) {
        return null;
      }
      for (let c = 0; c < $scope.views.length; c++) {
        if ($scope.views[c].name === name) {
          return $scope.views[c];
        }
      }
      return null;
    };

    $scope.scrollToTop = function() {
      const elem = angular.element($document[0].querySelector(
        '#table-container'));
      if (elem && elem[0]) {
        elem[0].scrollTop = 0;
      }
    };

    $scope.$watch(
      () => $scope.selectedView,
      function() {
        if ($scope.selectedView) {
          OcrViews.selectView($scope.selectedView)
        }
        if ($scope.selectedView === 'change_password') {
          OcrUtils.setPageTitle('Change Password')
        }
      },
    )

    $scope.updateViewData = function() {
      if ($scope.selectedView === "party_search") {
        OcrUtils.setPageTitle('Child Party Search')
        $scope.$parent.searchText = "";
        $scope.$parent.searchBoxVisible = true;
        $scope.$parent.searchPlaceholder = "Find child parties";

        $scope.results.data = [
          {
            id: 1,
            partyName: "Martin Gore",
            dateOfBirth: new Date(2015, 11, 15),
          },
          {
            id: 2,
            partyName: "Bernard Sumner",
            dateOfBirth: new Date(2014, 7, 30),
          },
          {
            id: 3,
            partyName: "David Gahan",
            dateOfBirth: new Date(2013, 5, 16),
          },
        ];
      } else {
        $scope.results.data = [];
      }
      $scope.results.count = $scope.results.data.length;

      $scope.$parent.searchText = "";
      if ($scope.searchViews.indexOf($scope.selectedView) >= 0) {
        $scope.$parent.searchBoxVisible = true;
      } else {
        $scope.$parent.searchBoxVisible = false;
      }
    };

    $scope.paginate = $scope.refresh = function() {
      $scope.updateViewData();
    };

    $scope.selectView = function(view) {
      OcrViews.selectView(view)
      $scope.selectedView = view;

      $scope.$parent.searchBoxVisible = false;

      if ($scope.views) {
        $scope.selectedViewData = $scope.getViewData(view);

        if (!$scope.checkPermissions($scope.selectedViewData)) {
          $scope.promise = null;
          return;
        }

        if ($scope.selectedViewData && $scope.selectedViewData.parent) {
          $scope.toggleView($scope.selectedViewData.parent);
        }
      }

      $scope.updateViewData();
    };

    $scope.assignToAppointment = function() {
      $mdDialog.show({
        controller: 'AssignToAppointmentDialogController',
        templateUrl: 'tmpl/assign-to-appointment-dialog.html',
        parent: angular.element($document[0].body),
        locals: {
          /* eslint-disable no-undef */
          myOpenAppointments: myOpenAppointments,
          /* eslint-enable no-undef */
        },
        clickOutsideToClose: false,
        fullscreen: $scope.customFullscreen, // Only for -xs, -sm breakpoints.
      })
        .then(function(_) {
          $scope.selected.length = 0;
        }, function() {
        });
    };

    $scope.changePassword = function() {
      $scope.userSettings.message = "";

      if ($scope.userSettings.currentPassword === $scope.userSettings.newPassword) {
        $scope.userSettings.message
          = "New password cannot be the same as current password.";
        return;
      }

      $scope.promise = $http.post("/api/changePassword", {
        currentPassword: $scope.userSettings.currentPassword,
        newPassword: $scope.userSettings.newPassword,
      }).then(function(_) {
        $scope.userSettings.message
          = "Your password has been changed successfully.";
        $scope.userSettings.currentPassword = "";
        $scope.userSettings.newPassword = "";
        $scope.userSettings.confirmPassword = "";
        $scope.promise = undefined;
        $scope.forms.userSettingsForm.$setPristine();
        $scope.forms.userSettingsForm.$setUntouched();
      }).catch(function(_) {
        $scope.userSettings.message
          = "Unable to change password.";
        $scope.promise = undefined;
      });
    };

    $scope.passwordStrengthCheck = function() {
      if (!$scope.userSettings.newPassword) {
        $scope.passwordStrength = 33;
        $scope.passwordClass = 'red';
        return;
      }
      const result = zxcvbn($scope.userSettings.newPassword);
      if (result.score === 0 || result.score === 1) {
        $scope.passwordStrength = 33;
        $scope.passwordClass = 'red';
      } else if (result.score === 2) {
        $scope.passwordStrength = 66;
        $scope.passwordClass = 'yellow';
      } else {
        $scope.passwordStrength = 100;
        $scope.passwordClass = 'green';
      }
    };

    $scope.passwordConfirm = function() {
      if ($scope.userSettings.newPassword === $scope.userSettings.confirmPassword) {
        $scope.forms.userSettingsForm.confirmPassword.$setValidity(
          "match", true);
      } else {
        $scope.forms.userSettingsForm.confirmPassword.$setValidity(
          "match", false);
      }
    }

    $scope.$on('$routeChangeSuccess', function() {
      if ($routeParams.view) {
        $scope.selectView($routeParams.view);
      } else if ($scope.views && $scope.views[0]) {
        $scope.selectView($scope.views[0].key); // select first view!
      }

      const paramFields = [
        "caseId",
        "appointmentId",
        "partyId",
        "invoiceNumber",
        "activityStatusId",
        "invoiceStatusId",
        "creditId",
        "articleId",
        "officeId",
        "officeStatusId",
        "stateCode",
        "judicialDistrictId",
        "countyId",
        "userId",
        "userStatusId",
        "activityTypeId",
        "activityStatusId",
        "genderId",
        "raceEthnicityId",
        "requestTypeId",
        "hearingTypeId",
        "personaId",
        "permissionId",
        "involvedPersonTypeId",
        "announcementId",
        "activityTypeStatusId",
        "appointmentOfficeAccessRequestReasonId",
        'activityGroupId',
        'billableItemTypeId',
        'appointmentCapacityId',
        'additionalFundsId',
        'caseTypeId',
        'permanencyGoalId',
        'placementId',
        'placementTypeId',
        'educationalSettingId',
        'caseStatusId',
        'privilegeHolderId',
        'appointmentEndReasonId',
        'otherApprovalsId',
        'childPartyCourtAttendanceOptionId',
        'ruleSetId',
        'quickSetup',
        "helpTextId",
        'urlLinkTextId',
        'bestInterestsContinuationReasonId',
        'attorneyRoleId',
      ];

      for (let c = 0; c < paramFields.length; c++) {
        if ($routeParams[paramFields[c]]) {
          $scope[paramFields[c]] = $routeParams[paramFields[c]];
          break;
        } else {
          $scope[paramFields[c]] = undefined;
        }
      }
    });

    // fix size of scroll area

    if (!$mdMedia("xs")) { // xs when fonts are very large for vision impaired
      $scope.listStyle = {
        maxHeight: `${$window.innerHeight - 385}px`,
      };
    } else {
      $scope.listStyle = {};
    }

    $window.addEventListener('resize', onResize, true);

    function onResize() {
      $timeout(function() {
        if (!$mdMedia("xs")) {
          $scope.listStyle.maxHeight = `${$window.innerHeight - 250
          }px`;
        } else {
          $scope.listStyle = {};
        }
      });
    }

    $scope.getViews();
    $scope.setPromise = function(promise) {
      $scope.promise = promise;
    };
  },
]);
