import "zxcvbn";
angular.module('CaresApp').controller('ResetCtrl', [
  '$scope',
  '$document',
  '$location',
  '$http',
  '$routeParams',
  '$timeout',
  'OcrAuth',
  'OcrUtils',
  function($scope, $document, $location, $http, $routeParams, $timeout, OcrAuth, OcrUtils) {
    OcrUtils.setPageTitle('Password Reset')

    $scope.forms = {}; // so we can use the forms from the controller
    $scope.reset = {};
    $scope.done = false;

    $scope.promise = undefined;

    OcrAuth.setSession(undefined)

    $scope.$parent.disableMenu = false;

    $scope.passwordStrength = 33;
    $scope.passwordClass = 'red';

    $scope.resetFocus = function() {
      $timeout(function() {
        $document[0].getElementById('password').focus();
      }, 250);
    };

    $scope.resetFocus();

    $scope.submit = function() {
      $scope.message = "";

      $scope.promise = $http.post("/api/reset", {
        resetToken: $routeParams.resetToken,
        password: $scope.reset.password,
      })
        .then(function() {
          $scope.message
            = "Your password has been reset.";
          $scope.promise = undefined;
          $scope.done = true;
        })
        .catch(function(error) {
          $scope.message
            = "There was an error resetting your password. Please retry.";

          if (error && error.data && error.data.status) {
            if (error.data.status === 400 || error.data.status === 401) {
              $scope.message
                = "Unable to reset your password. Your request may have expired. Please retry.";
            }
          }

          $scope.promise = undefined;
          $scope.resetFocus();
        });
    };

    $scope.login = function() {
      $location.path("/");
    };

    $scope.passwordStrengthCheck = function() {
      if (!$scope.reset.password) {
        $scope.passwordStrength = 33;
        $scope.passwordClass = 'red';
        return;
      }
      const result = zxcvbn($scope.reset.password);
      if (result.score === 0 || result.score === 1) {
        $scope.passwordStrength = 33;
        $scope.passwordClass = 'red';
      } else if (result.score === 2) {
        $scope.passwordStrength = 66;
        $scope.passwordClass = 'yellow';
      } else {
        $scope.passwordStrength = 100;
        $scope.passwordClass = 'green';
      }
    }

    $scope.passwordConfirm = function() {
      if ($scope.reset.password === $scope.reset.confirmPassword) {
        $scope.forms.resetForm.confirmPassword.$setValidity(
          "match", true);
      } else {
        $scope.forms.resetForm.confirmPassword.$setValidity(
          "match", false);
      }
    }
  },
]);
