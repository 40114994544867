angular.module('CaresApp').controller('MainCtrl', [
  '$scope',
  '$document',
  '$timeout',
  '$mdSidenav',
  '$mdMedia',
  'SessionService',
  'UserService',
  'OcrAuth',
  'OcrViews',
  function($scope, $document, $timeout, $mdSidenav, $mdMedia, SessionService, UserService,
    OcrAuth, OcrViews,
  ) {
    $scope.appName = "Colorado Attorney Reimbursement Electronic System";
    $scope.appShortName = "CARES";

    $scope.$mdMedia = $mdMedia;
    $scope.largeScreen = $mdMedia("gt-md");
    $scope.disableMenu = false; // used to hide hamburger icon when doc is fullscreen
    $scope.loading = false;

    if (!OcrAuth.isExpired() && !OcrAuth.session.user) {
      $scope.$parent.promise = UserService.get({
        id: OcrAuth.session.userId,
      }).$promise.then(function(user) {
        OcrAuth.session.user = user
        $scope.$broadcast('UserChanged', user)
      });
    }

    if (OcrAuth.session && OcrAuth.session.user) {
      $scope.user = OcrAuth.session.user
    }

    $scope.$watch(
      () => OcrAuth.session && OcrAuth.session.user,
      function() {
        if (OcrAuth.session && OcrAuth.session.user) {
          $scope.user = OcrAuth.session.user
        }
      },
    )

    $scope.isLoggedIn = () => OcrAuth.loggedIn

    $scope.signout = function() {
      if (OcrViews.currentView.key === "cases" || OcrViews.currentView.key === "my_appointments" || OcrViews.currentView.key === "general_settings") {
        $scope.$broadcast("LoggingOut");
      } else {
        SessionService.signout();
      }
    }

    $scope.toggleSidenav = function() {
      //if (!$mdSidenav('side-nav').isLockedOpen()) {
      //$mdSidenav('side-nav').toggle();
      //}
      if (!$mdSidenav('left').isLockedOpen()) {
        $mdSidenav('left').toggle();
      }
    };

    $scope.showPlaceholder = function() {
      const searchBox = angular.element($document[0].querySelector(
        '#search-box'));
      return searchBox.hasClass("focused");
    };

    $scope.searchboxFocused = function() {
      const searchBox = angular.element($document[0].querySelector(
        '#search-box'));
      searchBox.addClass("focused");
    };

    $scope.searchboxBlurred = function() {
      const searchBox = angular.element($document[0].querySelector(
        '#search-box'));
      searchBox.removeClass("focused");
    };

    // from https://davidwalsh.name/javascript-debounce-function
    function debounce(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) {
            func.apply(context, args);
          }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
          func.apply(context, args);
        }
      };
    }

    $scope.onSearch = debounce(function() {
      if ($scope.searchText === "") {
        $scope.searchText = null;
      }
      $scope.$broadcast("Searching", $scope.searchText);
    }, 500);

    $scope.$on("SetSearchBox", function(_, data) {
      $scope.searchBoxVisible = data;
    });

    $scope.$on("ViewChanged",
      (_, __, newView) => {
        if (!newView) {
          return;
        }
        $scope.searchBoxVisible = newView.usesSearch
        $scope.searchText = ''
      },
    )
  },
]);
