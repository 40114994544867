angular.module('CaresApp', [
  'ngRoute',
  'ngMaterial',
  'md.data.table',
  'ngMessages',
  'fixed.table.header',
  'ngResource',
  'ngIdle',
  'ngCookies',
  'ngStorage',
  'ngSanitize',
  'ui.calendar',
]);

/* eslint-disable-next-line angular/di-unused */
angular.module('CaresApp').config(function($mdThemingProvider, $mdIconProvider,
  $logProvider, $httpProvider,
  $locationProvider, $routeProvider, $localStorageProvider,
  $sessionStorageProvider, IdleProvider, KeepaliveProvider) {
  const KEEP_ALIVE_TIME = 5 * 60 // Send the keep alive signal every 5 minutes if a user is not idle
  const IDLE_TIME = KEEP_ALIVE_TIME * 2 // 10 minutes before a user is considered idle
  // 20 minutes after as user is idle, they are considered timed out
  const TIMEOUT_TIME = IDLE_TIME * 2;

  $mdThemingProvider.theme('default')
    .primaryPalette('blue', {
      'default': '800',
    })
    .accentPalette('red');

  $mdThemingProvider.theme('altTheme')
    .primaryPalette('green')
    .accentPalette('amber')
    .warnPalette('red');

  $logProvider.debugEnabled(false);
  $locationProvider.html5Mode(false);

  $routeProvider.when('/', {
    template: '<ocr-login flex></ocr-login>',
    resolve: {},
    requireLogin: false,
  })
    .when('/forgot', {
      controller: 'ForgotCtrl',
      templateUrl: 'tmpl/forgot.html',
      resolve: {},
      requireLogin: false,
    })
    .when('/reset/:resetToken', {
      controller: 'ResetCtrl',
      templateUrl: 'tmpl/reset.html',
      resolve: {},
      requireLogin: false,
    })
    .when('/views/:view', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
      reloadOnSearch: false,
    })
    .when('/case/:view/:caseId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/appointment/:view/:appointmentId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
      // this will allow us to update the search attributes without reloading the page.
      reloadOnSearch: false,
    })
    .when('/party/:view/:partyId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/invoice/:view/:invoiceNumber', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
      reloadOnSearch: false,
    })
    .when('/credit/:view/:creditId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/article/:view/:articleId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/announcement/:view/:announcementId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/office/:view/:officeId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/other_approvals/:view/:otherApprovalsId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/child_party_court_attendance_option/:view/:childPartyCourtAttendanceOptionId', {
      controller: "ViewCtrl",
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/gender/:view/:genderId', {
      controller: "ViewCtrl",
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/race_ethnicity/:view/:raceEthnicityId', {
      controller: "ViewCtrl",
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/request_type/:view/:requestTypeId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/state/:view/:stateCode', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/county/:view/:countyId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/user/:view/:userId', {
      controller: "ViewCtrl",
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/activity_status/:view/:activityStatusId', {
      controller: "ViewCtrl",
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/activity_type/:view/:activityTypeId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/hearing_type/:view/:hearingTypeId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/persona/:view/:personaId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/permission/:view/:permissionId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/case_type/:view/:caseTypeId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/judicial_district/:view/:judicialDistrictId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/placement_type/:view/:placementTypeId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/educational_setting/:view/:educationalSettingId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/permanency_goal/:view/:permanencyGoalId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/placement/:view/:placementId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/appointment_capacity/:view/:appointmentCapacityId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/child_party_end_reason/:view/:appointmentEndReasonId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when(
      '/appointment_office_access_request_reason/:view/:appointmentOfficeAccessRequestReasonId', {
        controller: 'ViewCtrl',
        templateUrl: 'tmpl/view.html',
        resolve: {},
        requireLogin: true,
      },
    )
    .when(
      '/attorney_role/:view/:attorneyRoleId', {
        controller: 'ViewCtrl',
        templateUrl: 'tmpl/view.html',
        resolve: {},
        requireLogin: true,
      },
    )
    .when('/activity_group/:view/:activityGroupId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/billable_item_type/:view/:billableItemTypeId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/involved_person_type/:view/:involvedPersonTypeId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/privilege_holder/:view/:privilegeHolderId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/additional_funds/:view/:additionalFundsId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/rule/:view/:ruleSetId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/help_text/:view/:helpTextId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/url_link_text/:view/:urlLinkTextId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/appointment_groups/:view/:appointmentGroupId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .when('/best_interests_continuation_reason/:view/:bestInterestsContinuationReasonId', {
      controller: 'ViewCtrl',
      templateUrl: 'tmpl/view.html',
      resolve: {},
      requireLogin: true,
    })
    .otherwise({
      redirectTo: '/appointment_group/:view/:appoint',
    });

  KeepaliveProvider.interval(KEEP_ALIVE_TIME)
  IdleProvider.idle(IDLE_TIME);
  IdleProvider.timeout(TIMEOUT_TIME);

  $localStorageProvider.setKeyPrefix('ocr-')
  $sessionStorageProvider.setKeyPrefix('ocr-')

  $httpProvider.interceptors.push('OcrAuth')
  $httpProvider.interceptors.push('OcrCacheBuster')
});

/* eslint-disable angular/no-run-logic */
/* eslint-disable-next-line angular/component-limit */
angular.module('CaresApp').run([
  '$rootScope',
  '$location',
  'SessionService',
  'Idle',
  '$mdDialog',
  '$sessionStorage',
  'OcrAuth',
  function($rootScope, $location, SessionService, Idle, $mdDialog, $sessionStorage, OcrAuth) {
    $rootScope.$watch(
      () => OcrAuth.loggedIn,
      function() {
        if (OcrAuth.loggedIn && !Idle.running()) {
          Idle.watch();
        } else if (!OcrAuth.loggedIn && Idle.running()) {
          Idle.unwatch();
        }
      },
    )

    $rootScope.$on('Keepalive', function() {
      SessionService.renew()
    })

    $rootScope.$on('IdleTimeout', function() {
      $mdDialog.show(
        $mdDialog.alert()
          .title('Session Time Out')
          .textContent('You have been logged out due to inactivity.')
          .ok('Close')
          .targetEvent(event),
      )
      SessionService.signout();
    });

    $rootScope.$on('$locationChangeSuccess', function() {
      if ($location.path() !== '/' && !$location.path().startsWith(
        '/reset/')) {
        $sessionStorage.previousUrl = $location.url();
      }
    });

    $rootScope.$on('$routeChangeStart', function(event, currRoute, _) {
      if (!currRoute.$$route) {
        console.error("Unable to get route: %O", $location.url());
      }

      const { requireLogin } = currRoute.$$route;
      const isLoggedIn = OcrAuth.loggedIn && !OcrAuth.isExpired();

      if (requireLogin && !isLoggedIn) {
        event.preventDefault();
        $location.path('/');
      }
    });
  },
]);
/* eslint-enable angular/no-run-logic */
